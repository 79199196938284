// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  padding: 24px;
  max-width: 540px;
  border-radius: 32px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/component_new/CustomTransitionModal/style.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,gBAAA;EACA,aAAA;EAEA,gBAAA;EACA,mBAAA;EACA,cAAA;AAAF","sourcesContent":[".payModal {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  box-shadow: 24px;\n  padding: 24px;\n  // max-height: 500px;\n  max-width: 540px;\n  border-radius: 32px;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
