// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popupContainer {
  border: 2px solid #333996;
  background: #ffffff;
  border-radius: 5px;
  padding: 5%;
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.actionContainer {
  display: flex;
  flex-direction: column;
}

.errorMessage, .otpErrorMessage {
  color: red;
  font-size: 12px;
  margin-top: 10px;
}

.otpErrorMessage {
  text-align: center;
}

.otpInput {
  margin-top: 30px;
}

.retryMsg {
  text-align: center;
  color: #333996;
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/container/LoginPopup/style.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,UAAA;EACA,eAAA;EACA,gBAAA;AACF;;AACA;EAEE,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,cAAA;EACA,gBAAA;AACF","sourcesContent":[".popupContainer {\n  border: 2px solid #333996;\n  background: #ffffff;\n  border-radius: 5px;\n  padding: 5%;\n  width: 90%;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.actionContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.errorMessage {\n  color: red;\n  font-size: 12px;\n  margin-top: 10px;\n}\n.otpErrorMessage {\n  @extend .errorMessage;\n  text-align: center;\n}\n\n.otpInput {\n  margin-top: 30px;\n}\n\n.retryMsg {\n  text-align: center;\n  color: #333996;\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
